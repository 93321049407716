import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Vant from "vant"
import "vant/lib/index.css"
import request from "lib/helper/request.js"

Vue.config.productionTip = false
Vue.prototype.$http = request
Vue.use(Vant)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")

import Vue from "vue"
import Vuex from "vuex"
import CreatePersistedstate from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    evaluationInfo: null,
    userInfo: {}
  },
  getters: {},
  mutations: {
    setEvaluationInfo(store, data) {
      store.evaluationInfo = data
    },
    setUserInfo(store, data) {
      store.userInfo = data
    }
  },
  actions: {},
  modules: {},
  plugins: [
    CreatePersistedstate({
      storage: window.sessionStorage
    })
  ]
})

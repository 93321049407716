import axios from "axios"

axios.defaults.timeout = 600000 // 设置超时时间为600秒
axios.interceptors.request.use(config => {
  config.headers["Content-Type"] = "application/json"
  if (config["Content-Type"]) {
    config.headers["Content-Type"] = config["Content-Type"]
  }
  // 必须返回config
  return config
})

axios.interceptors.response.use(
  function (response) {
    if (response.data.code === 200) {
      return response.data
    } else if (response.data.code === 500) {
      return false
    } else {
      return false
    }
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default axios

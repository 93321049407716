import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "about" */ "@/views/home.vue")
  },
  {
    path: "/evaluation-instructions",
    name: "EvaluationInstructionsView",
    component: resolve => require(["@/views/evaluation-instructions.vue"], resolve),
    meta: {
      title: "测评须知"
    }
  },
  {
    path: "/answer-multiple-situations",
    name: "answerMultipleSituations",
    component: resolve => require(["@/views/answer-multiple-situations.vue"], resolve),
    meta: {
      title: "测试"
    }
  },
  {
    path: "/report10001",
    name: "report10001",
    component: resolve => require(["@/views/report_views/report10001.vue"], resolve),
    meta: {
      title: "报告"
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
